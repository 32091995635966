/* index.tsx */
import { ORCHESTATION_URL } from "../../configuration/vortexConfig";

export async function loginUser(app_id: string, user_id: string) {
    return new Promise( async(resolve: any) => {
        await fetch(`${ORCHESTATION_URL}/v1/users/login/${app_id}/${user_id}`);
        resolve(true)
    })
}

export function logoutUser(app_id: string, user_id: string): void{
    ( async(app_id: string, user_id: string) => {
        await fetch(`${ORCHESTATION_URL}/v1/users/logout/${app_id}/${user_id}`);
    })(app_id, user_id);

}