import { Fragment } from 'react/jsx-runtime';
import { useContext } from 'react';


import { Conversation } from '../../../core/cosmos';
import { ChatUserContext } from '../../../core/chatContext';
import { ChatMessage } from '../../../core/cosmos';


import styles from './conversation.module.scss';




const ConversationComponent: React.FC<{ userConversation: Conversation[] }> = ({ userConversation }) => {
    const currChatContent: any = useContext(ChatUserContext);
    // Add user conversation to context
    // if ( userConversation.length) currChatContent.currChat.messages = userConversation

    const selectConversation = (conversation: Conversation, conversationIdx: number) => {
        // Create messages context
        const messages: {role: string, content: string}[] = currChatContent.currChat.messages ? [currChatContent.currChat.messages[0]] : [];

        for ( let idx = conversationIdx ; idx < conversation.messages.length; idx++) {

            if ( conversation.messages[idx].role === 'user') currChatContent.setUserAsks( (prevArray: any) => [...prevArray, conversation.messages[idx].content]);
            if ( conversation.messages[idx].role === 'assistant') currChatContent.setIaAnswers( (prevArray: any) => [...prevArray, conversation.messages[idx].content]);
            
            // Add history messages to current context
            if ( messages ) messages.push(
                {
                    role: conversation.messages[idx].role,
                    content:  conversation.messages[idx].content
                })
        }

        // Update context with new data
        if ( messages.length ) {
            currChatContent.setCurrChat( (currChat: any) => {
                currChat.messages = messages;
                return currChat;
            });
        }
    }

    return(
        <div className={styles.conversations}>
            <div className={styles.conversationsTitle}>Historial Conversaciones</div>
            {
                userConversation?.length 
                    ? (
                        userConversation.map((conversation: Conversation, index: number) => (  
                            <Fragment key={index}>
                                <div className={styles.conversationsSubtitle}>{(new Date(conversation.date)).toLocaleDateString('es-ES', 
                                    {   year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })}</div>  
                                {
                                    conversation.messages.map( (message: ChatMessage, index: number) => {
                                        if ( message.role === 'user') return ( 
                                            <div key={index} className={styles.conversationsQuestion} onClick={() => {
                                                selectConversation(conversation, index)
                                            }} role="button">
                                                {message.content}
                                            </div>
                                        )
                                        return null
                                    })
                                }
                            </Fragment>
                        ))
                    )
                    : null
            }
        </div>
    )
}

export { ConversationComponent }


