import { ORCHESTATION_URL } from "../../configuration/vortexConfig";
import { Conversation  } from "./models";
export class CosmosConn {

    private createNewUser(accessToken: string, conversation: Conversation): Promise<any>{
        return new Promise( async(resolve) => {
            const url = `${ORCHESTATION_URL}/v1/history/user`

            const newUser: {userId: string, conversation: Conversation[]} = {
                "userId": conversation.user,
                "conversation": [conversation]
            }

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify(newUser),
            };

            await fetch(url, requestOptions)
            resolve(true);
        });
    }

    private updateConversation(accessToken: string, conversation: Conversation): Promise<any>{
        return new Promise( async(resolve) => {
            const url = `${ORCHESTATION_URL}/v1/history/user/${conversation.user}/conversation/${conversation.id}`

            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify(conversation)
            };

            await fetch(url, requestOptions);
            resolve(true);
        });
    }
    
    fetchChatHistoryInit(idUser: string): Promise<any> {
        return new Promise( async(resolve: any) => {
            fetch(`${ORCHESTATION_URL}/v1/history/read/${idUser}`)
                .then(response => response.json())
                .then(data => {
                    if ( data ) resolve(JSON.parse(data));
                })
                .catch(error => {
                    console.error(error);
                    resolve([])
                });
        })
    }

    addConversation(accessToken: string, conversation: Conversation | null): Promise<boolean> {
        return new Promise( async(resolve: any) => {
            // Check if current user exists.
            const response = await fetch(`${ORCHESTATION_URL}/v1/history/check/${conversation?.user || 0}`);
            if ( response.status === 204 ) {
                // Create new user and add conversation
                if ( conversation ) await this.createNewUser(accessToken, conversation);
            } 

            if ( response.status === 200 ) {
                if ( conversation ) await this.updateConversation(accessToken, conversation);
            }
            resolve(true)
        })
    }
}