// history.scss
import { useContext, useEffect } from 'react';

// import { NewChatComponent } from './newChat/newChat';
import { ConversationComponent } from './conversation/conversation';
// import { ParametersComponent } from './parameters/parameters';
import { LogOutComponent } from './logout/logout';

import { CosmosConn } from '../../core/cosmos/api';
import { ChatUserContext } from '../../core/chatContext';

import { User } from './iHistory';

import styles from './history.module.scss';
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';


const HeaderElement = () => {
    const toogleMenu = () => {

        // Show / Hide icon
        if ( window.screen.width < 1366) {
            const IconMenuElement = document.querySelector<HTMLElement>(`.${styles.historyIconMenu}`);
            if ( IconMenuElement ) IconMenuElement.style.display = IconMenuElement?.style.display === 'none' || '' ? 'block' : 'none';
            const IconCloseElement = document.querySelector<HTMLElement>(`.${styles.historyIconClose}`);
            if ( IconCloseElement ) IconCloseElement.style.display = IconCloseElement?.style.display === 'block'  ? 'none' : 'block';
            const LogOutElement = document.querySelector<HTMLElement>(`.${styles.historyLogout}`);
            if ( LogOutElement ) LogOutElement.style.display = LogOutElement?.style.display === 'block'  ? 'none' : 'block';
        } else {
            const IconMenuElement = document.querySelector<HTMLElement>(`.${styles.historyIconMenu}`);
            if ( IconMenuElement ) IconMenuElement.style.display = IconMenuElement?.style.display === 'block' || '' ? 'none' : 'block';
            const IconCloseElement = document.querySelector<HTMLElement>(`.${styles.historyIconClose}`);
            if ( IconCloseElement ) IconCloseElement.style.display = IconCloseElement?.style.display === 'none' ? 'block' : 'none';
            const LogOutElement = document.querySelector<HTMLElement>(`.${styles.historyLogout}`);
            if ( LogOutElement ) LogOutElement.style.display = LogOutElement?.style.display === 'none'  ? 'block' : 'none';
        }

        const HistoryContainerElement = document.querySelector(`.${styles.history}`);
        HistoryContainerElement?.classList.toggle(`${styles.historyIsActive}`);

        const ConversationElement = document.querySelector(`.${styles.historyConversations}`);
        ConversationElement?.classList.toggle(`${styles.historyConversationsIsActive}`);


    }

    return(
        <div className={styles.historyIcon} onClick={toogleMenu} role="button">
            <div className={styles.historyIconMenu}>
                <Menu style={{fill: "#2c2a29"}}></Menu>
            </div>
            <div className={styles.historyIconClose}>
                <Close style={{fill: "#2c2a29"}}></Close>
            </div>
        </div>
    )
}

const HistoryComponent: React.FC<User> = ({ idUser }) => {

    const currChatContent: any = useContext(ChatUserContext);

    const Cosmos_Connection = new CosmosConn();

    useEffect(() => {
        if ( idUser ) {
            // Get user history and to context
            Cosmos_Connection.fetchChatHistoryInit(idUser)
                .then( mongoResponse => {
                    currChatContent.setCurrHistory(mongoResponse?.conversation.reverse() ||  []);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        idUser
    ])

    return(
        <div className={styles.history}>
            <HeaderElement/>
            {/* <NewChatComponent/> */}
            <ConversationComponent userConversation={currChatContent.currHistory}/>
            {/* <ParametersComponent></ParametersComponent> */}
            <LogOutComponent></LogOutComponent>
        </div>
        
    )
}

export { HistoryComponent}