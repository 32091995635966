// ChatContext/index.ts
import { createContext } from "react";

import { API_DATA_SOURCES, API_PARAMETERS } from "../../configuration/vortexConfig"; 

export const ChatUserContext = createContext({});

export const InitalChatRequest = {
    data_sources: [API_DATA_SOURCES],
    ...API_PARAMETERS,
}

