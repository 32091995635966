
import { getAPIResponse } from "../../core/http";
import { CosmosConn } from '../../core/cosmos/api';
import { ChatMessage, Conversation } from "../../core/cosmos";

import { SIZE_CONVERSATION, ORCHESTATION_URL, API_DATA_SOURCES } from "../../configuration/vortexConfig";

export class ChatController {

    private async generateAnswer(aiApiRequest: RequestOpenAI, accessToken: string): Promise<any>{
        return new Promise ( async (resolve, reject) => {
            const response = await getAPIResponse(aiApiRequest, accessToken);
            let message: any[] = [];
            response.forEach((element: any) => {
                if (element.delta.content) {
                    if (element.delta.content === "Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.") 
                        reject("Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.");
                    message.push(element);
                }
                
            });
            resolve(message);
        });
    }

    private addMessageToConversation(
        idUser: string,
        prevConversation: {
            prev: Conversation[],
            question: string, 
            apiResponse: string
    }): {
        createConversation: boolean, 
        conversation: Conversation | null
    } | null {
        const NumberMessages: number = prevConversation.prev?.length ? prevConversation.prev[0].messages.length : 0;
        
        const addMessage = (question: string, response: string): ChatMessage[] => {
            return [
                {
                "id": (NumberMessages + 1).toString(),
                "role": "user",
                "content": question,
                "date": (new Date()).toISOString()
            },{
                "id": (NumberMessages + 2).toString(),
                "role": "assistant",
                "content": response,
                "date": (new Date()).toISOString()
            }]
        }

        const dateFirstMessage = prevConversation.prev.length ? new Date(prevConversation.prev[0].date).toDateString() : new Date();
        let newConversation: Conversation = {
            "id": crypto.randomUUID(),
            "user": idUser,
            "messages": [],
            "date": (new Date()).toISOString()
        }
        // Check spaces of messages in conversation
        switch( true ) {
            case NumberMessages === 0:
                // New conversation
                newConversation.messages = [...addMessage(prevConversation.question, prevConversation.apiResponse)];
                return {
                    createConversation: true,
                    conversation: newConversation
                };

            case NumberMessages + 2 > SIZE_CONVERSATION * 2:
                // New conversation
                newConversation.messages = [...addMessage(prevConversation.question, prevConversation.apiResponse)];
                return {
                    createConversation: true,
                    conversation: newConversation
                };

            case dateFirstMessage !== new Date().toDateString():
                // New conversation
                newConversation.messages = [...addMessage(prevConversation.question, prevConversation.apiResponse)];
                return {
                    createConversation: true,
                    conversation: newConversation
                };

            default:
                newConversation.messages = [...prevConversation.prev[0].messages, ...addMessage(prevConversation.question, prevConversation.apiResponse)];
                return {
                    createConversation: false,
                    conversation: newConversation
                };
        }
    }

    proccessResponse(currChatContent: any, idUser: string, question: any){
        const _currChatContent = currChatContent
        const Cosmo_Conn = new CosmosConn();

        this.generateAnswer(_currChatContent.currChat, _currChatContent.accessToken)
          .then(response => {
            let apiResponse = '';
            const NumberChunks = response.length;
            response.forEach((chunk: any, idx: number) => {
              setTimeout(() => {
                apiResponse += response[idx].delta.content
                currChatContent.setIaAnswers((prevArray: string[]) => [...prevArray.slice(0, prevArray.length - 1), apiResponse]);
                // Add response to context at last entry
                if (idx === NumberChunks - 1) {
                  // Add api response to history context and save @ cosmodb
                  currChatContent.setCurrHistory((curr: any) => {
                    const { createConversation, conversation } = { ...this.addMessageToConversation(
                      idUser,
                      {
                        prev: curr,
                        question: question,
                        apiResponse: apiResponse
                      }
                    ) };

                    // debugger;
                    // console.log(createConversation);
                    // console.log(conversation);
                    // debugger;
                    // Save conversation @ cosmodb
                    if (conversation) (async () => {
                      await Cosmo_Conn.addConversation(currChatContent.accessToken, conversation);
                    })();

                    if (createConversation) {
                      // Add api response to chat context
                      currChatContent.setCurrChat((prevContext: RequestOpenAI) => {
                        prevContext.messages = [...prevContext.messages || [], {
                          "role": "assistant",
                          "content": apiResponse
                        }]
                        return prevContext
                      })
                      return [conversation, ...curr]
                    } else {
                      // Update chat context
                      // If it is last message of context create new context.
                      currChatContent.setCurrChat((prevContext: RequestOpenAI) => {
                        if (prevContext.messages?.length === API_DATA_SOURCES.parameters.top_n_documents) {
                          prevContext.messages = [];  // Initialize messages
                          return prevContext
                        } else {
                          prevContext.messages = [...prevContext.messages || [], {
                            "role": "assistant",
                            "content": apiResponse
                          }]
                          return prevContext
                        }
                      })
                      return [conversation, ...curr.slice(1,)]
                    }
                  });
                }
              }, idx * 50);
            });
          })
          .catch(response => {
            currChatContent.setIaAnswers((prevArray: string[]) => [...prevArray.slice(0, prevArray.length - 1), response]);
          });
    }

    generatePrompt(message: string) {
        const newMessage = {
            role: 'user',
            content: message
        }
        return  [
            newMessage
        ]
        
    }

    checkFileIsPdf(file: File): boolean {
        return true
    }

    async uploadFile(formData: any, uuid: string): Promise<boolean>  {
        return new Promise( async(resolve: any) => {
            if ( formData ) {
                const response = await fetch(`${ORCHESTATION_URL}/v1/ephemeral/upload/${uuid}`,{
                    method: 'POST',
                    body: formData
                });
                if ( response.status === 204 ) resolve(true);
            };
            resolve(false);
        })
    }

    async RemoveFile(uuid: string | null): Promise<boolean>  {
        return new Promise( async(resolve: any) => {
            if ( uuid ) {
                const response = await fetch(`${ORCHESTATION_URL}/v1/ephemeral/collection/${uuid}`,{
                    method: 'DELETE'
                });
                if ( response.status === 204 ) resolve(true);
            };
            resolve(false);
        })
    } 
}

