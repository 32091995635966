import { ChatController } from '../chat.controller';

import styles from './fileContext.module.scss';

import CloseIcon from '@mui/icons-material/Close';


const FileContextComponent: React.FC<{ fileName: string, setFileName: any, currChatContent: any }> = ({ fileName, setFileName, currChatContent }) => {
   
    const handleRemoveFile = () => {
        const Chat_Controller = new ChatController();
        Chat_Controller.RemoveFile(currChatContent.currChat.data_sources[0].parameters.ephemeralId)
            .then(() => {
                currChatContent.currChat.data_sources[0].parameters.ephemeralId = null;        
                setFileName('');
        });
    };

    return (
        <div className={styles.fileContextComponent}>
            { fileName && fileName !== "Cargado el fichero. Un momento por favor ..." ? (
                <>
                    <span>{fileName}</span>
                    <span>
                        <CloseIcon className={styles.fileContextComponentIcon} onClick={() => handleRemoveFile()}></CloseIcon>
                    </span>
                </>
            ):  fileName && fileName === "Cargado el fichero. Un momento por favor ..." ? (<span>{fileName}</span>) : null}
        </div>
    )
}

export { FileContextComponent }


