// chat.tsx
import { useState, useContext, createRef, useEffect, Fragment } from 'react';

import { ChatUserContext } from '../../core/chatContext';
import { ChatController } from './chat.controller';

import { WelcomeComponent } from './welcome/welcome';
import { DialogComponent } from '../dialog/dialog';
import { FileContextComponent  } from './fileContext/fileContext';
import { FooterComponent } from './footer/footer';

import styles from './chat.module.scss';

import Send from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const ChatComponent: React.FC<{ idUser: any, userPhoto: any }> = ({ idUser, userPhoto }) => {

  const Chat_Controller = new ChatController();

  const currChatContent: any = useContext(ChatUserContext);

  const textAreaRef = createRef<HTMLTextAreaElement>();

  const [fileName, setFileName] = useState('');
  
  useEffect(() => {
    // Focus in textarea when render page
    textAreaRef.current?.focus();
  });

  const PromptComponent = () => {
    const [formData, setFormData] = useState({ question: "" });

    const handleTextareaKeyPress = (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();

      if (formData.question) {
        // Add new question to array
        currChatContent['setCurrChat']((prevContext: RequestOpenAI) => {
          prevContext.messages = [...prevContext.messages || [], {
            "role": "user",
            "content": formData.question
          }]
          return prevContext
        });

        // Update user question
        currChatContent.setUserAsks((prevArray: any) => [...prevArray, formData.question]);

        // Add loading message
        currChatContent.setIaAnswers((prevArray: any) => [...prevArray, 'Generando respuesta...']);

        Chat_Controller.proccessResponse(currChatContent, idUser, formData.question)
        // Update response of ia
        // Chat_Controller.generateAnswer(currChatContent.currChat, currChatContent.accessToken)
        //   .then(response => {
        //     let apiResponse = '';
        //     const NumberChunks = response.length;
        //     response.forEach((chunk: any, idx: number) => {
        //       setTimeout(() => {
        //         apiResponse += response[idx].delta.content
        //         currChatContent.setIaAnswers((prevArray: string[]) => [...prevArray.slice(0, prevArray.length - 1), apiResponse]);
        //         // Add response to context at last entry
        //         if (idx === NumberChunks - 1) {
        //           // Add api response to history context and save @ cosmodb
        //           currChatContent.setCurrHistory((curr: any) => {
        //             const { createConversation, conversation } = { ...Chat_Controller.addMessageToConversation(
        //               idUser,
        //               {
        //                 prev: curr,
        //                 question: formData.question,
        //                 apiResponse: apiResponse
        //               }
        //             ) };

        //             // Save conversation @ cosmodb
        //             if (conversation) (async () => {
        //               await Cosmo_Conn.addConversation(currChatContent.accessToken, conversation);
        //             })();

        //             if (createConversation) {
        //               // Add api response to chat context
        //               currChatContent.setCurrChat((prevContext: RequestOpenAI) => {
        //                 prevContext.messages = [...prevContext.messages || [], {
        //                   "role": "assistant",
        //                   "content": apiResponse
        //                 }]
        //                 return prevContext
        //               })
        //               return [conversation, ...curr]
        //             } else {
        //               // Update chat context
        //               // If it is last message of context create new context.
        //               currChatContent.setCurrChat((prevContext: RequestOpenAI) => {
        //                 if (prevContext.messages?.length === API_DATA_SOURCES.parameters.top_n_documents) {
        //                   prevContext.messages = [];  // Initialize messages
        //                   return prevContext
        //                 } else {
        //                   prevContext.messages = [...prevContext.messages || [], {
        //                     "role": "assistant",
        //                     "content": apiResponse
        //                   }]
        //                   return prevContext
        //                 }
        //               })
        //               return [conversation, ...curr.slice(1,)]
        //             }
        //           });
        //         }
        //       }, idx * 50);
        //     });
        //   })
        //   .catch(response => {
        //     currChatContent.setIaAnswers((prevArray: string[]) => [...prevArray.slice(0, prevArray.length - 1), response]);
        //   });
      }
    };

    const handleFileChange = (event: any) => {
      const _file = event.target.files[0]
      const _formData = new FormData();
      _formData.append('pdf_file', _file);

      if (Chat_Controller.checkFileIsPdf(_file)) {
        currChatContent.currChat.data_sources[0].parameters.ephemeralId = crypto.randomUUID();
        setFileName('Cargado el fichero. Un momento por favor ...');
        Chat_Controller.uploadFile(_formData, currChatContent.currChat.data_sources[0].parameters.ephemeralId)
          .then(() => {
            setFileName(_file['name']);
          });
      }
    };

    return (
        <Fragment>
            <FileContextComponent fileName={fileName} setFileName={setFileName} currChatContent={currChatContent}></FileContextComponent>
            <div className={styles.promptComponent}>
                <form className={styles.promptComponentInput} onSubmit={handleSubmit}>
                    <div className={styles.promptComponentUploadfile}>
                        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                            <div className={styles.promptComponentUploadButton}>
                                <AttachFileIcon className={styles.promptComponentIcon}></AttachFileIcon>
                            </div>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className={styles.promptComponentInputTextareaContainer}>
                        <textarea 
                            ref={textAreaRef}
                            className={styles.promptComponentInputTextarea} 
                            onChange={(e) => setFormData({...formData, question: e.target.value})}
                            onKeyDown={handleTextareaKeyPress} // Handles the "Enter" key
                            value={formData.question} 
                            id="body"
                            name="body" 
                            placeholder='En qué te puedo ayudar'></textarea>
                    </div>
                    <button className={styles.promptComponentSendButton} type='submit'>
                        <Send className={styles.promptComponentIcon}></Send>
                    </button>
                </form>
            </div>
        </Fragment>
    )
  }

  const showMainComponent = () => {
    if ( !currChatContent.currChat.messages ) {
        return (
            <WelcomeComponent/>
        )
    } else {
        return (
            <DialogComponent userPhoto={userPhoto} userAsks={currChatContent.userAsks} iaAnswers={currChatContent.iaAnswers}></DialogComponent>
        )
    }
  }


  return(
      <div className={styles.chatComponent}>
          <>
              {showMainComponent()}
              <PromptComponent />
              <FooterComponent />
          </>
      </div>
  )
}

export { ChatComponent }
