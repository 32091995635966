/* dialog.tsx */
import { Fragment, useEffect } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'


import { formatHtml } from '../../core/formatHtml';

import logoHAI from './../../assets/img/Vortex_AI_Logo.svg';

import styles from './dialog.module.scss';
// import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

const AskComponent = (props: { userPhoto: any, userAsks: any }) => {
    const {userPhoto, userAsks} = props;
    return (
        <div className={styles.dialogContainerAskComponent}>
            <div className={styles.dialogContainerAskComponentText}>
                <ReactMarkdown className={styles.markdownTable} remarkPlugins={[remarkGfm]}>{typeof userAsks === 'string' ? formatHtml(userAsks) : null}</ReactMarkdown>
            </div>
            <div>
                { userPhoto && <img src={userPhoto} className={styles.dialogContainerPhoto} alt="Foto de perfil" />}
            </div>
        </div>
    )
}

const AnswerComponent = (props: { iaAnswers: any }) => {
    
    const {iaAnswers} = props;
    if (!iaAnswers) return (<></>); 
    return (
        <div className={styles.dialogContainerAnswerComponent}>
            <div className={styles.dialogContainerChatAvatar}>
                <img src={logoHAI} alt="logo" />
            </div>
            <div className={styles.dialogContainerAnswerComponentText}>
                <ReactMarkdown className={styles.markdownTable} remarkPlugins={[remarkGfm]}>{typeof iaAnswers === 'string' ? formatHtml(iaAnswers) : null}</ReactMarkdown>
            </div>
        </div>
    )
}

// const FeedbackComponent = () => {
//     return (
//         <div className={styles.dialogFeedback}>
//             <span className={styles.dialogFeedback__thumbdown}>
//                 <ThumbDownOffAltIcon></ThumbDownOffAltIcon>
//             </span>
//             <span className={styles.dialogFeedback__thumbup}>
//                 <ThumbUpOffAltIcon></ThumbUpOffAltIcon>
//             </span>
//         </div>
//     )
// } 

const DialogComponent = (props: any) => {

    const {userPhoto, userAsks, iaAnswers} = props;
        
    const lines = userAsks.length; 

    useEffect(() => {
        // Move scroll to bottom when update userAsks to see last response of IA
        const elemento = document.getElementById('bottomDialogContainer');
        elemento?.scrollIntoView({ behavior: 'smooth' });
    },[userAsks, iaAnswers]);

    return (
        <div className={styles.dialogComponent}>
            <div className={styles.dialogContainer}>
                {[...Array(lines)].map((elementInArray, index) => ( 
                    <Fragment key={index}>
                        <AskComponent key={`ask-${index}`} userPhoto={userPhoto} userAsks={userAsks[index]}></AskComponent>
                        <AnswerComponent key={`answer-${index}`} iaAnswers={iaAnswers[index]}></AnswerComponent>
                        {/* <FeedbackComponent></FeedbackComponent> */}
                    </Fragment>
                    ) 
                )}
                <div id="bottomDialogContainer"></div>
            </div>
            
        </div>
    )
}

export { DialogComponent }